var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"9"}},[_c('h2',[_vm._v("Serial Number")])])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":function($event){_vm.formData = {
          id: '',
          code: '',
          type: '',
          model: '',
          commission: 0,
        }
        _vm.formNavigationDrawer = true
        _vm.$refs.formChild.clearForm()}}},[_vm._v("Tambah")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"info","small":"","depressed":"","loading":_vm.loadingButtonImport},on:{"click":function($event){return _vm.openFile('import_data')}}},[_vm._v("Import")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"import_data","id":"import_data","accept":".xlsx, .xls"},on:{"change":function($event){return _vm.importFile($event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Filter")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Cari","outlined":"","dense":"","hide-details":true},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":"","depressed":""},on:{"click":_vm.getData}},[_vm._v("Filter")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","depressed":"","outlined":""},on:{"click":function($event){_vm.search = ''
            _vm.getData()}}},[_vm._v("Reset")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingTable,"items-per-page":_vm.limit,"footer-props":{
        'items-per-page-options': [5, 10, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.commission",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRupiah(item.commission))+" ")]}},{key:"item.option",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"warning","x-small":""},on:{"click":function($event){_vm.formNavigationDrawer = true
                _vm.formData = {
                  id: item.id,
                  code: item.code,
                  type: item.type,
                  model: item.model,
                  commission: _vm.formatRupiahWithoutPrefix(item.commission),
                }}}},[_vm._v("Ubah")]),_c('v-btn',{attrs:{"color":"error","x-small":""},on:{"click":function($event){_vm.deleteDialog = true
                _vm.selectedDelete = item}}},[_vm._v("Hapus")])],1)],1)]}}],null,true)})],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.alert.success ? 'success' : 'error',"right":"","bottom":""},model:{value:(_vm.alert.status),callback:function ($$v) {_vm.$set(_vm.alert, "status", $$v)},expression:"alert.status"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Yakin dihapus?")]),_c('v-card-actions',[_c('v-spacer'),_c('div',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","small":"","depressed":"","loading":_vm.loadingDeleteButton,"disabled":_vm.loadingDeleteButton},on:{"click":_vm.deleteData}},[_vm._v("Ya")]),_c('v-btn',{attrs:{"color":"error","outlined":"","depressed":"","small":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Tidak")])],1)],1)],1)],1),_c('v-navigation-drawer',{attrs:{"fixed":"","bottom":"","temporary":"","right":"","width":"380"},model:{value:(_vm.formNavigationDrawer),callback:function ($$v) {_vm.formNavigationDrawer=$$v},expression:"formNavigationDrawer"}},[_c('Form',{ref:"formChild",attrs:{"selectedData":_vm.formData,"closeNav":_vm.closeNav,"setAlert":_vm.setAlert,"getData":_vm.getData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }