<template>
  <v-card elevation="0">
    <v-form ref="form">
      <v-card-title>{{ selectedData.id == '' ? 'Tambah' : 'Ubah' }} Data</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.code"
              label="Serial Number"
              placeholder="Serial Number"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Serial Number harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.type"
              label="Tipe"
              placeholder="Tipe"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Tipe harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.model"
              label="Model"
              placeholder="Model"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Model harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.commission"
              label="Komisi"
              placeholder="Komisi"
              outlined
              hide-details="auto"
              required
              :rules="[v => (!!v || v == 0) || 'Komisi harus diisi!']"
              onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
              dense
              @keyup="changeFormat"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" small depressed @click="validate" :loading="loadingButton">Simpan</v-btn>
            <v-btn color="primary" small depressed outlined class="ml-3" @click="closeNav">Batal</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import { formatRupiahWithoutPrefix } from '@/utils'

export default {
  props: ['closeNav', 'getData', 'setAlert', 'selectedData'],
  data() {
    return {
      loadingButton: false,
    }
  },
  methods: {
    clearForm() {
      this.$refs.form.resetValidation()
    },
    validate() {
      if (this.$refs.form) {
        if (this.$refs.form.validate()) {
          this.submit()
        }
      }
    },
    submit() {
      this.loadingButton = true
      const token = localStorage.getItem('token')
      if (this.selectedData.id != '') {
        axios
          .patch(
            `${process.env.VUE_APP_API_ENDPOINT}serial-number-product/${this.selectedData.id}`,
            {
              code: this.selectedData.code,
              type: this.selectedData.type,
              model: this.selectedData.model,
              commission: this.selectedData.commission != '' ? parseInt(this.selectedData.commission.replaceAll('.', '')) : 0,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      } else {
        axios
          .post(
            `${process.env.VUE_APP_API_ENDPOINT}serial-number-product`,
            {
              code: this.selectedData.code,
              type: this.selectedData.type,
              model: this.selectedData.model,
              commission: this.selectedData.commission != '' ? parseInt(this.selectedData.commission.replaceAll('.', '')) : 0,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      }
    },
    changeFormat() {
      if (this.selectedData.commission != '') {
        this.selectedData.commission = formatRupiahWithoutPrefix(parseFloat(this.selectedData.commission.replaceAll('.', '')))
      }
    }
  },
}
</script>

<style>
</style>